import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

export default function Header() {
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);

  const openAboutModal = () => {
    setShowAboutModal(!showAboutModal);
    setShowRulesModal(false);
    document.body.classList.add("modal-open");
  };

  const openRulesModal = () => {
    setShowRulesModal(!showRulesModal);
    setShowAboutModal(false);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setShowAboutModal(false);
    setShowRulesModal(false);
    document.body.classList.remove("modal-open");
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    const handleClickOutside = (event) => {
      if (event.target.classList.contains("header") && (showAboutModal || showRulesModal)) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showAboutModal, showRulesModal]);

  return (
    <>
      <header className="header">
        <Link className="" to="/">
          <div className="title">Lights Out</div>
        </Link>
        <div className="space-x-2">
          <a className="nav-link cursor-pointer" onClick={openAboutModal}>
            About
          </a>
          <span className="text-neutral-300 font-extralight"> | </span>
          <a className="nav-link cursor-pointer" onClick={openRulesModal}>
            How to play
          </a>
        </div>
      </header>

      {showRulesModal && (
        <div className="fixed flex items-center justify-center z-50 w-full modal px-3 rules-modal">
          <div className="relative w-full max-w-2xl h-full">
            <div className="relative rounded-t-2xl h-full modal-bg">
              <div className="flex items-start justify-between p-4 rounded-t border-b-2">
                <h3 className="text-xl font-bold text-neutral-800">How to play</h3>
                <button type="button" className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center text-neutral-800" data-modal-hide="defaultModal" onClick={closeModal}>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-5 pb-44 space-y-6 text-justify h-full overflow-y-auto text-xs leading-relaxed text-neutral-700">
                <div>
                  <h6 className="text-sm font-semibold leading-relaxed text-neutral-800 flex -ml-1.5 mb-1">
                    <MdKeyboardArrowRight className="pt-0.5" size={18} /> <span>Anticipate the Start</span>
                  </h6>
                  <p>Wait for the five red lights to go out. The anticipation is just like the start of an F1 race – it’s about being ready to spring into action.</p>
                  <img className="w-full rounded-md mt-2" src="./images/rules/redlights.png" />
                </div>
                <hr />
                <div>
                  <h6 className="text-sm font-semibold leading-relaxed text-neutral-800 flex -ml-1.5 mb-1">
                    <MdKeyboardArrowRight className="pt-0.5" size={18} /> <span>Start Racing Against Time</span>
                  </h6>
                  <p>As soon as the lights go out, the timer starts! This is your cue to put the pedal to the metal and start the game.</p>
                  <img className="w-full rounded-md mt-2" src="./images/rules/timer.png" />
                </div>

                <hr />

                <div>
                  <h6 className="text-sm font-semibold leading-relaxed text-neutral-800 flex -ml-1.5 mb-1">
                    <MdKeyboardArrowRight className="pt-0.5" size={18} /> <span>Solve the Puzzle</span>
                  </h6>
                  <p>Look at the jumbled letters and figure out the name of the F1 driver. The letters are all there – they just need to be put in the right order.</p>
                  <img className="w-full rounded-md mt-2" src="./images/rules/puzzle.png" />
                </div>

                <hr />

                <div>
                  <h6 className="text-sm font-semibold leading-relaxed text-neutral-800 flex -ml-1.5 mb-1">
                    <MdKeyboardArrowRight className="pt-0.5" size={18} /> <span>Type Your Answer</span>
                  </h6>
                  <p>Use the on-screen keyboard to type in your answer. If you make a mistake, don't worry – just hit the backspace key to correct it. Once you’re confident in your answer, hit the "Submit" button to lock in your guess.</p>
                  <img className="w-full rounded-md mt-2" src="./images/rules/type.png" />
                </div>

                <hr />

                <div>
                  <h6 className="text-sm font-semibold leading-relaxed text-neutral-800 flex -ml-1.5 mb-1">
                    <MdKeyboardArrowRight className="pt-0.5" size={18} /> <span>Check Your Results and Leaderboard</span>
                  </h6>
                  <p>Immediately see your results and a quick view of the leaderboard. Did you make it to the top?</p>
                  <img className="w-full rounded-md mt-2" src="./images/rules/result.png" />
                </div>

                <hr />

                <div>
                  <h6 className="text-sm font-semibold leading-relaxed text-neutral-800 flex -ml-1.5 mb-1">
                    <MdKeyboardArrowRight className="pt-0.5" size={18} /> <span>Come Back Daily for New Challenges</span>
                  </h6>
                  <p>Remember, the puzzle resets every 24 hours, so there’s always a new challenge waiting for you every day. Keep racing and climbing the ranks!</p>
                </div>

                <hr />

                <p className="text-sm leading-relaxed text-neutral-600">That's it, Enjoy!</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAboutModal && (
        <div className="fixed flex items-center justify-center z-50 w-full modal px-3">
          <div className="relative w-full max-w-2xl h-full">
            <div className="relative rounded-t-2xl h-full modal-bg">
              <div className="flex items-start justify-between p-4 rounded-t border-b-2">
                <h3 className="text-xl font-bold text-neutral-800">About</h3>
                <button type="button" className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center text-neutral-800" data-modal-hide="defaultModal" onClick={closeModal}>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-5 pb-44 space-y-6 text-justify h-full overflow-y-auto">
                <p className="text-sm leading-relaxed text-neutral-900">
                  <span className="font-semibold">Lights Out</span> - How fast can you react?
                </p>
                <p className="text-sm leading-relaxed text-neutral-700">At the stroke of midnight, the game resets, and a new jumbled puzzle awaits you. Each day, we drop a scrambled grid featuring the letters of a Formula 1 driver's name. Your mission? Decipher the name as swiftly as you can. The moment you press "Start", it's a race against time and players worldwide.</p>
                <p className="text-sm leading-relaxed text-neutral-700">But it's more than a simple unscramble - it's an adrenaline-pumping experience. Immerse yourself in the F1 atmosphere with the tension of the five red lights at the start line. When they go out, your timer begins, thrusting you into the fast-paced world of motorsports.</p>
                <p className="text-sm leading-relaxed text-neutral-700">Don't just guess - race. As you solve the puzzle, your time ticks away. Submit your answer and see where you stand on our global leaderboard. Compare your reaction time to fans from every corner of the world and strive for the pole position in our daily challenge.</p>
                <p className="text-sm leading-relaxed text-neutral-700">
                  With a fresh puzzle every 24 hours, <b>Lights Out</b> is your daily sprint in the Formula 1 of word games. Are you ready to put your F1 knowledge and reflexes to the test?
                </p>
                <p className="text-sm leading-relaxed text-neutral-700">Start your engines, and may the fastest fan win!</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
