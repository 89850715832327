import "../styles/leaderboard.css";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import BASE_URL from "../config";
import { ThreeDots } from "react-loading-icons";

function Leaderboard() {
  const [ranking, setRanking] = useState("");
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setloading] = useState(true);

  const getleaderboard = () => {
    axios
      .get(`${BASE_URL}/getleaderboard`)
      .then((response) => {
        if (response.data.message === "success") {
          let result = response.data.result;
          setRanking(result.ranking[0] ? result.ranking[0].rank : "");
          setLeaderboard(result.leaderboard);
        }
      })
      .catch((error) => {
        console.error("Error with the puzzle:", error);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const timeConverter = (temptimex) => {
    const temptime = temptimex * 1000;
    const milliseconds = temptime % 1000;
    const seconds = Math.floor(temptime / 1000) % 60;
    const minutes = Math.floor(temptime / 60000);
    return `${minutes > 0 ? minutes + ":" : ""}${seconds < 10 ? "0" : ""}${seconds}.${milliseconds.toString().padStart(3, "0")}`;
  };

  useEffect(() => {
    getleaderboard();
  }, []);

  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentYear = date.getFullYear();
  const currentMonthIndex = date.getMonth();
  const currentMonthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][currentMonthIndex];
  const today = currentMonthName + " " + currentDay + ", " + currentYear;

  return (
    <Layout>
      <main className="bg-black flex">
        {loading === true ? (
          <>
            <div className="flex flex-1 mx-auto max-w-[550px] text-left px-4 py-10">
              <div className="flex flex-1 bg-main-dark border border-[#222222] text-neutral-500 items-center text-center leading-8 px-7 py-10 rounded-xl">
                <div>
                  Please hold while our stewards check for any penalties or time deductions
                  <ThreeDots className="mx-auto opacity-50 w-20 mt-10" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mx-auto w-full max-w-[550px] text-left px-4 py-10">
              <div className="relative">
                {ranking != "" ? <div className="text-white text-2xl absolute text-center w-full top-3 -left-0.5 tracking-wider font-bold">P{ranking}</div> : <></>}
                <img src="./images/flag.png" className="w-60 mx-auto" />
              </div>
              <div className="bg-main-dark p-5 rounded-lg flex justify-between items-center mt-7">
                <div className="flex-1 text-white text-center text-xs leading-relaxed tracking-wider font-medium">
                  <div>{today}</div>
                </div>
              </div>
              <div className="leaderboard mb-3">
                {leaderboard.map((row, index) => (
                  <div key={index} className={`${row.rank == ranking ? "highlight" : ""}`}>
                    <span>P{row.rank}</span>
                    <span>{timeConverter(row.time)}</span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </main>
    </Layout>
  );
}

export default Leaderboard;
