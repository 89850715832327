import React, { useState, useEffect } from "react";
import "../styles/RaceLights.css";

const RaceLights = ({ onGreen, onOff }) => {
  const [lights, setLights] = useState(Array(5).fill("#111"));

  useEffect(() => {
    const redTimers = lights.map((_, index) =>
      setTimeout(() => {
        setLights((lights) => lights.map((light, i) => (i <= index ? "red" : light)));
      }, 1000 * (index + 1))
    );

    const greenTimer = setTimeout(() => {
      setLights(Array(5).fill("green"));
      onGreen && onGreen(); // Callback when lights turn green
    }, 6000);

    const hideTimer = setTimeout(() => {
      setLights(Array(5).fill("off"));
      onOff && onOff(); // Callback when lights turn off
    }, 8000);

    return () => {
      redTimers.forEach((timer) => clearTimeout(timer));
      clearTimeout(greenTimer);
      clearTimeout(hideTimer);
    };
  }, [onGreen, onOff]); // Add callbacks to the dependency array

  return (
    <div className="race-lights">
      {lights.map((light, index) => (
        <div key={index} className={`light ${light}`}></div>
      ))}
    </div>
  );
};

export default RaceLights;
