import "../styles/puzzle.css";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../config";
import Sentry from "react-activity/dist/Sentry";
import "react-activity/dist/Sentry.css";
import RaceLights from "../components/RaceLights";
import { MdBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaFlag } from "react-icons/fa";
import { ThreeDots } from "react-loading-icons";
import { RWebShare } from "react-web-share";

function Jumbled() {
  const [puzzleId, setpuzzleId] = useState("");
  const [allletters, setallletters] = useState("");
  const [showLights, setshowLights] = useState(true);
  const [word, setWord] = useState("");
  const [clickedIndices, setClickedIndices] = useState([]);

  const [error, seterror] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const [loading, setloading] = useState(true);
  const letters = allletters.split("");

  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  const [finished, setFinished] = useState(false);
  const [ranking, setRanking] = useState("");
  const [leaderboard, setLeaderboard] = useState([]);
  const [verifyloading, setverifyloading] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState(Date.now());

  const date = new Date();
  const currentDate = new Date().toUTCString();

  // Function to sync time with the database
  const syncTimeWithDatabase = async () => {
    if (running) {
      try {
        const response = await axios.post(`${BASE_URL}/sync-time`, {
          time: time / 1000,
        });
      } catch (error) {
        console.error("Error syncing time with the database:", error);
      }
    }
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("stopwatchTime"));
    const currentDate = new Date().toDateString();

    if (savedData && savedData.date === currentDate) {
      setTime(savedData.time);
    } else {
      setTime(0); // It's a new day or no data saved yet, start from 0
      localStorage.setItem("stopwatchTime", JSON.stringify({ time: 0, date: currentDate }));
    }
  }, []);

  useEffect(() => {
    let intervalId;
    if (running) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime + 10;
          // localStorage.setItem("stopwatchTime", newTime.toString());
          localStorage.setItem("stopwatchTime", JSON.stringify({ time: newTime, date: new Date().toDateString() }));
          return newTime;
        });
      }, 10);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [running]);

  useEffect(() => {
    const syncInterval = setInterval(() => {
      const now = Date.now();
      // Check if 2 seconds have passed since the last sync
      if (running && now - lastSyncTime >= 2000) {
        syncTimeWithDatabase();
        setLastSyncTime(now); // Update the last sync time
      }
    }, 500); // Check every 500 milliseconds to ensure we don't miss the 2-second window

    return () => clearInterval(syncInterval); // Cleanup on unmount
  }, [running, lastSyncTime]);

  const handleGreen = () => {
    // setshowWords(true);
  };

  const handleOff = () => {
    setshowLights(false);
    if (finished == false) {
      start();
    }
  };

  const handleButtonClick = (letter, index) => {
    setWord((prevWord) => prevWord + letter);
    setClickedIndices((prevIndices) => [...prevIndices, index]);
  };

  const removeletter = () => {
    if (word !== "") {
      setWord(word.slice(0, -1));
      setClickedIndices((prevIndices) => prevIndices.slice(0, -1));
    }
  };

  const start = () => {
    setRunning(true);
  };

  const stop = () => {
    setRunning(false);
  };

  const resume = () => {
    setRunning(true);
  };

  const formatTime = () => {
    const milliseconds = time % 1000;
    const seconds = Math.floor(time / 1000) % 60;
    const minutes = Math.floor(time / 60000);
    return `${minutes > 0 ? minutes + ":" : ""}${seconds < 10 ? "0" : ""}${seconds}.${milliseconds.toString().padStart(3, "0")}`;
  };

  const timeConverter = (temptimex) => {
    const temptime = temptimex * 1000;
    const milliseconds = temptime % 1000;
    const seconds = Math.floor(temptime / 1000) % 60;
    const minutes = Math.floor(temptime / 60000);
    return `${minutes > 0 ? minutes + ":" : ""}${seconds < 10 ? "0" : ""}${seconds}.${milliseconds.toString().padStart(3, "0")}`;
  };

  const getpuzzle = () => {
    axios
      .get(`${BASE_URL}/getpuzzle`)
      .then((response) => {
        if (response.data.message === "success") {
          let result = response.data.result;
          if (result.puzzle[0].name != "") {
            if (result.answer[0]) {
              setTime(result.answer[0].time * 1000);
              if (result.answer[0].answered == 1) {
                setFinished(true);
                setRanking(result.ranking[0].rank);
                setLeaderboard(result.leaderboard);
                setWord(result.puzzle[0].name);
              } else {
                // localStorage.setItem("stopwatchTime", result.answer[0].time * 1000);
                localStorage.setItem("stopwatchTime", JSON.stringify({ time: result.answer[0].time * 1000, date: new Date().toDateString() }));
              }
            } else {
              localStorage.removeItem("stopwatchTime");
              localStorage.clear();
            }

            setallletters(result.puzzle[0].jumbled);
            setpuzzleId(result.puzzle[0].id);
            setloading(false);
          } else {
            setCancelled(true);
          }
        } else {
          setCancelled(true);
        }
      })
      .catch((error) => {
        // setloading(false);
        console.error("Error with the puzzle:", error);
        setCancelled(true);
      });
  };

  useEffect(() => {
    getpuzzle();
  }, []);

  const verifyword = () => {
    if (word !== "" && allletters.length === clickedIndices.length - 1 && verifyloading == false) {
      setRunning(false);
      setverifyloading(true);
      const wordData = {
        value: word,
        time: time / 1000,
      };
      axios
        .post(`${BASE_URL}/verifypuzzle`, wordData)
        .then((response) => {
          let result = response.data.result;
          if (response.data.message === "success") {
            if (result.answer[0] !== null) {
              if (result.answer[0].answered == 1) {
                setTime(result.answer[0].time * 1000);
                // localStorage.setItem("stopwatchTime", result.answer[0].time * 1000);
                localStorage.setItem("stopwatchTime", JSON.stringify({ time: result.answer[0].time * 1000, date: new Date().toDateString() }));
                setRunning(false);
                setFinished(true);
                setRanking(result.ranking[0].rank);
                setLeaderboard(result.leaderboard);
                setWord(result.puzzle[0].name);
              } else {
              }
            }
          } else {
            setWord("");
            seterror("Wrong Answer. Try Again");
            setClickedIndices([]);
            setTimeout(function () {
              seterror("");
            }, 2000);
            resume();
          }
          setverifyloading(false);
        })
        .catch((error) => {
          seterror(error);
          setTimeout(function () {
            seterror("");
          }, 2000);
          setverifyloading(false);
          resume();
        });
    } else {
      seterror("Too short");
      setTimeout(function () {
        seterror("");
      }, 2000);
    }
  };

  const handleShare = async (textToShare) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Web Share Example",
          text: textToShare,
          url: window.location.href,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Web Share API is not supported in your browser.");
    }
  };

  // localStorage.clear();

  return (
    <Layout>
      <main className="flex flex-col">
        {loading === true ? (
          <>
            {cancelled == true ? (
              <div className="flex flex-1 bg-main-dark border border-[#222222] text-neutral-400 text-justify leading-8 m-4 px-7 py-10 rounded-xl">
                <div className="space-y-10">
                  <div>
                    <FaFlag className="text-7xl text-red-700" />
                  </div>
                  <div>Looks like we're stuck on the starting grid! Let's get the pit crew on this puzzle hiccup and race back to the action shortly.</div>
                  <div>Check back later. Thank you!</div>
                </div>
              </div>
            ) : (
              <div className="flex flex-1 w-full mx-auto max-w-[550px] px-4 py-10">
                <div className="flex flex-1 bg-main-dark border border-[#222222] text-neutral-500 items-center text-center leading-8 px-7 py-10 rounded-xl">
                  <div className="w-full">
                    Formation Lap is Underway
                    <ThreeDots className="mx-auto opacity-50 w-20 mt-10" />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="puzzle mx-auto">
              {showLights == true ? (
                <div className="bg-main-dark rounded-lg">
                  <RaceLights onGreen={handleGreen} onOff={handleOff} />
                </div>
              ) : (
                <div className="bg-main-dark p-5 rounded-lg flex justify-between items-center">
                  {ranking !== "" ? (
                    <div className="flex-1 text-white text-xs leading-relaxed tracking-wider font-normal">
                      <div>The race is over!</div>
                      <div>
                        You finished <b className="text-main">P{ranking}</b>
                      </div>
                    </div>
                  ) : (
                    <div className="flex-1 text-white text-xs leading-relaxed tracking-wider font-normal">
                      <div>
                        It's <b>Lights Out</b>!
                      </div>
                      <div>and away we go..</div>
                    </div>
                  )}
                  <div className="flex-1 text-white text-3xl font-semibold text-right">{formatTime()}</div>
                  <div></div>
                </div>
              )}

              {showLights == false ? (
                <>
                  <div className={`word-box`}>{word}</div>
                  {finished ? (
                    <>
                      <div className="relative">
                        <div className="text-white text-2xl absolute text-center w-full top-3 -left-0.5 tracking-wider font-bold">P{ranking}</div>
                        <img src="./images/flag.png" className="w-60 mx-auto" />
                      </div>
                      <div className="leaderboard-sm backdrop-filter backdrop-blur-sm -mt-14 mb-3">
                        {leaderboard.map((row, index) => (
                          <div key={index} className={`${row.rank == ranking ? "highlight" : ""}`}>
                            <span>P{row.rank}</span>
                            <span>{timeConverter(row.time)}</span>
                          </div>
                        ))}
                      </div>
                      {/* <div className="success-box">Chequered flag 🏁 The race is over! Well Done</div> */}
                      <div className="gap gap-cols-2 flex gap-4 mt-3">
                        {/* <div className="flex justify-center mb-2"> */}
                        <Link to="/leaderboard" className="flex-1">
                          <button className="w-full play-btn-alt !text-[12px] !py-4">View Leaderboard</button>
                        </Link>
                        <RWebShare
                          data={{
                            text: `🏁 Lights Out ${puzzleId} 🏁%0A%0AMy time: ${formatTime()} | Currently P${ranking}%0A%0ATake on today's challenge and see where you rank 🕹️%0A%0ATry it now:`,
                            url: "https://LightsOut.developmint.xyz/",
                            title: "Share your result",
                          }}
                          disableNative={true}
                        >
                          <button className="play-btn !bg-neutral-100 !text-[12px] !py-4">Share</button>
                        </RWebShare>
                        {/* </div> */}
                      </div>
                    </>
                  ) : (
                    <div>
                      <div className={`error-box ${error === "" ? "" : "show"}`}>
                        <span>{error}</span>
                      </div>
                      <div className="letter-box">
                        {letters.map((letter, index) => (
                          <button className="letter-cell" key={index} onClick={() => handleButtonClick(letter, index)} disabled={clickedIndices.includes(index)}>
                            <span>{letter}</span>
                          </button>
                        ))}
                        <button className="letter-cell" onClick={() => handleButtonClick(" ", 100)} disabled={clickedIndices.includes(100)}>
                          Space
                        </button>
                      </div>
                      <div className={`action-btns`}>
                        <button className={`btn-delete`} onClick={() => removeletter()} disabled={word === "" || verifyloading ? true : false}>
                          <MdBackspace size={21} />
                        </button>
                        {verifyloading === true ? <Sentry animating={verifyloading} className="mx-3" /> : <div className="mx-7"></div>}
                        <button className={`btn-submit`} onClick={() => verifyword()} disabled={word === "" || allletters.length !== clickedIndices.length - 1 || verifyloading ? true : false}>
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </main>
    </Layout>
  );
}

export default Jumbled;
