import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Jumbled from "./pages/Jumbled";
import Leaderboard from "./pages/Leaderboard";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/play" element={<Jumbled />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        {/* <Route path="/english" element={<English />} />
        <Route path="/urdu" element={<Urdu />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
